<template>
  <v-card flat tile style="overflow:auto;height:550px">
    <v-card-title class="secondary">
      <span>{{orgName}}</span>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2 elevation-1"
            fab
            dark
            x-small
            color="primary"
            v-on="on"
            @click="editLocEntityTree()"
          >
            <v-icon dark>edit</v-icon>
          </v-btn>
        </template>
        <span>Edit entity tree</span>
      </v-tooltip>
      <v-tooltip top v-if="locEntityTree.length == 0">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2 elevation-1"
            fab
            dark
            x-small
            color="secondary"
            v-on="on"
            @click="createLocEntityTree()"
          >
            <v-icon dark>add</v-icon>
          </v-btn>
        </template>
        <span>Create entity tree</span>
      </v-tooltip>
    </v-card-title>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      stream
      color="primary darken-1"
    ></v-progress-linear>
    <v-card-text>
      <v-treeview
        v-if="locEntityTree.length !=0"
        open-all
        expand-icon="keyboard_arrow_down"
        :items="locEntityTree"
      >
        <template v-slot:label="{ item, open }">
          <!-- <v-card flat tile class="elevation-0"> -->
          <v-container fluid pa-0>
            <v-row no-gutters align="end">
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-row no-gutters align="center">
                  <v-col cols="12" sm="10" md="10" lg="10">
                    <v-icon color="primary">{{getLocEntityTypeIcon(item.loc_entity_type_idx)}}</v-icon>
                    <span>{{item.name}}</span>
                  </v-col>
                  <v-col cols="12" md="2" lg="2" sm="2" align="end">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-avatar
                          color="primary"
                          size="24"
                          v-if="item.meters.length != 0"
                          v-on="on"
                        >
                          <span class="white--text">{{item.meters.length}}</span>
                        </v-avatar>
                      </template>
                      <span>Meter count</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          color="primary darken-1"
                          @click="showMetersOnMap(item)"
                          v-on="on"
                        >
                          <v-icon class="pr-0">room</v-icon>
                        </v-btn>
                      </template>
                      <span>view on map</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-if="item.meters.length != 0" style="overflow:auto" class="ml-4">
                  <v-list dense max-height="auto" flat>
                    <v-list-item-group color="blue">
                      <v-list-item v-for="(meter, i) in item.meters" :key="i" @click="onMeterClick(meter)">
                        <v-list-item-icon class="mr-1">
                          <v-icon color="blue" small>developer_board</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="meter.name + ' - ' + meter.serial_number"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <!-- </v-card> -->
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
export default {
  name: "LocationsWidget",
  components: {},
  data() {
    return {
      locEntityTypeIcon: {
        Building: "apartment",
        Floor: "view_headline",
        Phase: "grid_on",
        Gateway: "device_hub"
      }
    };
  },
  mounted() {
     this.$store.dispatch("MDMStore/getLocEntityTree");
  },
  watch: {
    locEntityTree() {}
  },
  computed: {
    loading() {
      return this.$store.getters["MDMStore/getterloadingState"](
        "LOC_ENTITY_TREE"
      );
    },
    locEntityTree() {
      return this.$store.getters["MDMStore/getterLocEntityTree"];
    },
    orgName() {
      return this.$store.getters["MDMStore/getterOrgName"];
    }
  },
  methods: {
    getLocEntityTypeIcon(locEntity) {
      var locEntityTypeName = this.$store.getters[
        "MDMStore/getterLocEntityTypeName"
      ](locEntity);
      return this.locEntityTypeIcon[locEntityTypeName];
    },
    createLocEntityTree(){
      this.$router.push({path:"/new/loc_entity", props:{addOrEdit:'add'}});
    },
    showMetersOnMap(item) {
      EventBus.$emit("on-show-meters-on-map", item);
    },
    editLocEntityTree() {
      this.$router.push({path:"/edit/loc_entity", props:{addOrEdit:'edit'}});
    },
    onMeterClick(meter){
      this.$store.commit(
          "MDMStore/SET_SELECTED_METER",
          meter.serial_number
        );
      this.$router.push({ path: "/analytics" });
      EventBus.$emit("select-analytics-meter", meter.serial_number);
    }
  }
};
</script>
<style scoped>
.v-sheet--offset {
  top: -14px;
  position: relative;
}
</style>

  