<template>
  <v-card tile elevation-0 flat>
    <v-card-title class="secondary">
      <span>Device status</span>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2 elevation-1"
            fab
            dark
            x-small
            color="primary"
            v-on="on"
            @click="refreshDeviceStatus()"
          >
            <v-icon dark>refresh</v-icon>
          </v-btn>
        </template>
        <span>refresh</span>
      </v-tooltip>
    </v-card-title>
    <v-progress-linear :active="loading" :indeterminate="loading" absolute color="primary darken-1"></v-progress-linear>
    <v-card-text>
      <v-container fluid pa-0 mt-2>
        <v-row no-gutters>
          <v-col cols="12" sm="4" md="4" lg="4" class="text-md-center">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-icon color="green lighten-1" large>check_circle</v-icon>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col cols="12" sm="12" md="12" lg="12">
                <a class="display-3 font-weight-light black--text" @click="navigateToMeters('false')">{{onlineDeviceCount}}</a>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" class="text-md-center">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-icon color="red lighten-1" large>remove_circle</v-icon>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col cols="12" sm="12" md="12" lg="12">
                <a class="display-3 font-weight-light black--text" @click="navigateToMeters('true')">{{offlineDeviceCount}}</a>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" class="text-md-center">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-icon color="yellow lighten-1" large>error</v-icon>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col cols="12" sm="12" md="12" lg="12">
                <a class="display-3 font-weight-light black--text">{{faultDeviceCount}}</a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="align-end">
      <v-icon color="primary">developer_board</v-icon>
      <span
        class="pl-2"
      >Device count - {{Number(onlineDeviceCount) + Number(offlineDeviceCount) + Number(faultDeviceCount)}}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
export default {
  components: {},
  data() {
    return {
      //loading: true
    };
  },
  computed: {
    loading() {
      return this.$store.getters["MDMStore/getterloadingState"](
        "DEVICE_STATUS"
      );
    },
    onlineDeviceCount() {
      return this.$store.getters["MDMStore/getterOnlineMetersCount"];
    },
    offlineDeviceCount() {
      return this.$store.getters["MDMStore/getterOfflineMetersCount"];
    },
    faultDeviceCount() {
      return this.$store.getters["MDMStore/getterFaultMetersCount"];
    }
  },
  mounted() {
    
     
  },
  methods: {
    refreshDeviceStatus() {
      this.$store.dispatch({
        type: "MDMStore/getOrgDetails",
        orgId: localStorage.getItem("orgId")
      });
    },
    navigateToMeters(filter){
      EventBus.$emit("filter-meter", filter);
      this.$router.push({path:"/settings/manage-devices"});
    }
  }
};
</script>

<style>
</style>