var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"overflow":"auto","height":"550px"},attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"secondary"},[_c('span',[_vm._v(_vm._s(_vm.orgName))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.editLocEntityTree()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("edit")])],1)]}}])},[_c('span',[_vm._v("Edit entity tree")])]),(_vm.locEntityTree.length == 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"fab":"","dark":"","x-small":"","color":"secondary"},on:{"click":function($event){return _vm.createLocEntityTree()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)]}}],null,false,2788189145)},[_c('span',[_vm._v("Create entity tree")])]):_vm._e()],1),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","stream":"","color":"primary darken-1"}}),_c('v-card-text',[(_vm.locEntityTree.length !=0)?_c('v-treeview',{attrs:{"open-all":"","expand-icon":"keyboard_arrow_down","items":_vm.locEntityTree},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"10","lg":"10"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.getLocEntityTypeIcon(item.loc_entity_type_idx)))]),_c('span',[_vm._v(_vm._s(item.name))])],1),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2","sm":"2","align":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.meters.length != 0)?_c('v-avatar',_vm._g({attrs:{"color":"primary","size":"24"}},on),[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.meters.length))])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Meter count")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary darken-1"},on:{"click":function($event){return _vm.showMetersOnMap(item)}}},on),[_c('v-icon',{staticClass:"pr-0"},[_vm._v("room")])],1)]}}],null,true)},[_c('span',[_vm._v("view on map")])])],1)],1),(item.meters.length != 0)?_c('v-row',{staticClass:"ml-4",staticStyle:{"overflow":"auto"}},[_c('v-list',{attrs:{"dense":"","max-height":"auto","flat":""}},[_c('v-list-item-group',{attrs:{"color":"blue"}},_vm._l((item.meters),function(meter,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.onMeterClick(meter)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"color":"blue","small":""}},[_vm._v("developer_board")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(meter.name + ' - ' + meter.serial_number)}})],1)],1)}),1)],1)],1):_vm._e()],1)],1)],1)]}}],null,false,1769940440)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }