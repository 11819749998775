<template>
  <v-container fluid pa-0 class="fill-height">
    <v-row no-gutters class="fill-height">
      <v-col cols="12" md="7" lg="7" v-if="locEntityTreeLength != 0">
        <!-- <v-card class="fill-height" v-if="locEntityTreeLength != 0"> -->
        <google-map></google-map>
        <!-- </v-card> -->
      </v-col>
       <v-col cols="12" md="5" lg="5" class="pa-2">
        <v-row no-gutters>
          <v-col cols="12" md="12" lg="12">
            <device-status></device-status>
          </v-col>
          <v-col cols="12" md="12" lg="12" class="pt-2">
            <loc-entity-tree></loc-entity-tree>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoogleMap from "../components/home/googleMap";
// import ConsumptionWidget from "../components/home/consumptionWidget";
// import LocationsWidget from "../components/home/locationsWidget";
import LocEntityTree from "../components/home/LocEntityTree";
import DeviceStatus from "../components/home/deviceStatus";

///SET A ROUTER VIEW for zero loc entity view
export default {
  components: {
    GoogleMap,
    // ConsumptionWidget,
    // LocationsWidget,
    LocEntityTree,
    DeviceStatus
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      var self = vm;
      if (self.$store.getters["MDMStore/getterOrgId"] == "") {
        self.$store.dispatch({
          type: "MDMStore/getOrgDetails",
          router: self.$router
        });
        self.$store.dispatch("MDMStore/getLocationEntityTypes");
        self.$store.dispatch("MDMStore/getMeterKinds");
        self.$store.dispatch("MDMStore/getLocEntityTree");
        // self.$store.dispatch({
        //   type: "MDMStore/getMeters",
        //   orgId: localStorage.getItem("orgId")
        // });
      }
    });
  },
  mounted() {
  },
  computed: {
    locEntityTreeLength() {
      return this.$store.getters["MDMStore/getterlocEntityTreeLength"];
    }
  },
  mounted() {},
  created() {}
};
</script>
