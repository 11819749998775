<template>
  <div style="height:100%">
    <gmap-map ref="googlemap" :center="center" :zoom="zoom" style="width:100%;height: 100%">
      <!-- v-bind:options="mapStyle" -->
      <gmap-cluster>
        <gmap-marker
          :icon="isMeter(m) ? {path:'M12 2.02c-5.51 0-9.98 4.47-9.98 9.98s4.47 9.98 9.98 9.98 9.98-4.47 9.98-9.98S17.51 2.02 12 2.02zM11.48 20v-6.26H8L13 4v6.26h3.35L11.48 20z',scale:1.2,fillColor:getFillColor(m),fillOpacity:0.7, strokeColor:'#fffff'} : require('@/assets/City-Marker.svg') "
          :key="m.name"
          v-for="m in markers"
          :animation="2"
          :position="{lat:Number(m.latitude), lng:Number(m.longitude)}"
          :clickable="true"
          @click="onLocEntityClick(m)"
        ></gmap-marker>
      </gmap-cluster>
       <gmap-info-window
        :options="locInfoOptions"
        v-for="m in markers"
         :opened="locInfoWinOpen"
        :position="{lat:Number(m.latitude), lng:Number(m.longitude)}"
        :key="'info'+m.name"
      >
        <v-card tile class="elevation-0">
          <v-card-text class="pa-0">
            <span
              class="black--text pl-2"
            >{{m.hasOwnProperty('name') ? m.name : ""}}</span>
          </v-card-text>
        </v-card>
      </gmap-info-window>
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false"
      >
        <v-card tile class="elevation-0 pa-0">
          <v-card-title>
            <v-icon color="green" v-if="selectedMarker.isOffline == 'false'">check_circle</v-icon>
            <v-icon color="red" v-else>highlight_off</v-icon>
            <span
              class="black--text pl-2"
            >{{selectedMarker.hasOwnProperty('name') ? selectedMarker.name : ""}}</span>
          </v-card-title>
        </v-card>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCVwaxhZM4UtfzzyxZbRtqIo8bjz-tlljE",
    v: "3.31"
  }
});

export default {
  name: "GoogleMap",
  components: {
    // ScaleLoader
    GmapCluster
  },
  data() {
    return {
      zoom: 5,
      markers: [],
      selectedMarker: {},
      center: {
        lat: 10,
        lng: 13.558513
      },
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      locInfoWinPos: {
        lat: 0,
        lng: 0
      },
      locInfoWinOpen: true,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: 0,
        }
      },
      locInfoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        }
      }
    };
  },

  mounted() {
    EventBus.$on("on-show-meters-on-map", (le) => {
      this.locEntityTree = [le];
      this.locInfoWinOpen =  this.isMeter(le) ? false : true;
    });
    this.markers = this.$store.getters["MDMStore/getterLocEntityTree"];
    this.center = {
      lat: this.markers[0].latitude,
      lng: this.markers[0].longitude
    };
    this.$refs.googlemap.$mapPromise.then(map => {
      var centerControlDiv = document.createElement("div");
      this.CenterControl(centerControlDiv, map);

      centerControlDiv.index = 1;
      map.controls[2].push(centerControlDiv);
    });
  },
  computed: {
    locEntityTree: {
      get: function() {
        return this.$store.getters["MDMStore/getterLocEntityTree"];
      },
      set: function(newValue) {
        if (newValue.length != 0) {
          this.markers = [];
          this.markers = newValue;
          this.center = {
            lat: newValue[0].latitude,
            lng: newValue[0].longitude
          };
          this.zoom = newValue[0].hasOwnProperty("meter_kind_idx") ? 9 : 5;
        }
      }
    }
  },
  methods: {
    CenterControl(controlDiv) {
      var self = this;
      // Set CSS for the control border.
      var controlUI = document.createElement("div");
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.marginBottom = "22px";
      controlUI.style.textAlign = "center";
      controlUI.title = "Click to view installations";
      controlDiv.appendChild(controlUI);

      // Set CSS for the control interior.
      var controlText = document.createElement("div");
      controlText.style.color = "rgb(25,25,25)";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlText.style.fontSize = "16px";
      controlText.style.lineHeight = "38px";
      controlText.style.paddingLeft = "5px";
      controlText.style.paddingRight = "5px";
      controlText.innerHTML = "Show Installations";
      controlUI.appendChild(controlText);

      // Setup the click event listeners
      controlUI.addEventListener("click", function() {
        self.markers = [];
        self.markers = self.$store.getters["MDMStore/getterLocEntityTree"];
         self.locInfoWinOpen = true;
        self.center = {
          lat: self.markers[0].latitude,
          lng: self.markers[0].longitude
        };
        self.zoom = 5;
      });
    },
    onLocEntityClick(locEntity) {
      var self = this;
      if (!self.isMeter(locEntity)) {
        
        this.locEntityTree = self.hasMeters(locEntity)
          ? locEntity.meters
          : locEntity.children;
        self.locInfoWinOpen =  self.hasMeters(locEntity) ? false : true; 
      } else {
         self.selectedMarker = locEntity;
        this.infoWindowPos = {
          lat: self.selectedMarker.latitude,
          lng: self.selectedMarker.longitude
        };
        this.infoWinOpen = !this.infoWinOpen;
      }
    },
    isMeter(obj) {
      return obj.hasOwnProperty("meter_kind_idx");
    },
    hasMeters(locEntity) {
      return !(
        locEntity.hasOwnProperty("children") &&
        locEntity.hasOwnProperty("meters") &&
        locEntity.meters.length == 0
      );
    },
    getFillColor(obj) {
      return obj.isOffline == "true" ? "red" : "green";
    }
  }
};
</script>
